<template>
  <div class="group-record">
    <template v-if="dataLoaded">
      <div class="" v-if="recordList.length">
        <group-record-item :info="item" v-for="(item, index) in recordList" :key="index" @click="checkCoupon(item)"/>
        <template v-if="fetching">
          <div class="text-gray text-center"><i class="fa fa-spinner fa-spin" style="font-size: 2em;"></i></div>
        </template>
      </div>
      <div class="empty" v-else>
        <img src="../../../static/icons/group-empty.svg" alt="empty">
        <p>{{$t('group.labelRecordEmpty')}}</p>
        <button class="button is-primary is-rounded is-medium" @click="$router.push('/group/start')">{{$t('group.btnRecordStartGroup')}}</button>
      </div>
    </template>
    <div class="text-gray text-center" v-else>
      <i class="fa fa-spinner fa-spin" style="font-size: 2em;" aria-hidden="true"></i>
      <p>Loading</p>
    </div>
    <modal :show="showCouponDetail" @close="closeModal">
      <div slot="body">
        <div v-if="couponLoaded">
          <h4>团购优惠券</h4>
          <coupon :coupon="couponInfo" :enable="false"/>
          <p class="text-center">
            <router-link to="/app/coupons">
              <button class="btn default">查看更多优惠</button>
            </router-link>
          </p>
        </div>
        <div class="text-center text-gray" v-else>
          <i class="fa fa-spinner fa-spin" style="font-size: 2em;" aria-hidden="true"></i>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GroupRecordItem from '../../components/layout/GroupRecordItem.vue'
import api from '../../api/'
import Coupon from '../../components/layout/Coupon.vue'
export default {
  name: 'group-record',
  components: { GroupRecordItem, Coupon },
  data: () => ({
    isEmpty: true,
    dataLoaded: false,
    recordList: [],
    current: 1,
    total: 1,
    fetching: false,
    showCouponDetail: false,
    couponLoaded: false
  }),
  mounted () {
    this.fetchRecordList().then(pages => {
      this.total = pages
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleScroll)
    next()
  },
  methods: {
    handleScroll (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target.documentElement
      if (scrollHeight - scrollTop - clientHeight < 100) {
        this.fetchRecordList()
      }
    },
    closeModal () {
      this.showCouponDetail = false
    },
    checkCoupon (item) {
      this.showCouponDetail = true
      this.fetchCouponInfo(item.couponNo)
    },
    fetchCouponInfo (id) {
      this.couponLoaded = false
      return api.checkCoupon(id).then(data => {
        this.couponLoaded = true
        this.couponInfo = data.coupon
      })
    },
    fetchRecordList () {
      if (this.fetching) return
      if (this.current > this.total) return
      this.fetching = true
      return api.fetchRecordList(this.current).then(data => {
        if (data.errors) {
          return
        }
        this.current += 1
        this.recordList = this.recordList.concat(data.data)
        return data.pages
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        this.fetching = false
        this.dataLoaded = true
      })
    }
  }
}
</script>

<style lang="scss">
  .group-record {
    padding: 16px 20px;
    @media (min-width: 768px) {
      max-width: 520px;
      margin: 0 auto;
    }
    .empty {
      margin-top: 45px;
      text-align: center;
      img {
        height: 70px;
        width: 70px;
      }
      p {
        margin: 30px auto 18px;
      }
    }
  }
</style>
